import {get} from 'lodash-es'
import * as fcl from '@onflow/fcl'

import {
  comics,
  dimensionX,
  dimensionXAdmin,
  init,
  isLoggedIn,
} from '@crypthulhu/dmx-contracts'

import * as wagmiCore from '@wagmi/core'
import * as ethers from 'ethers'

import {DMXComics, DMXHero, DMXHeroStore, DMXHeroStaker} from '@crypthulhu/ethdmx-contracts'
import config from 'global/config'

import { sleep } from '@crypthulhu/utils/utils.mjs'

// Put some stuff in global object so we can reach it easily from unity's jslib
// modules
globalThis.crypthulhu = {
  // Flow
  fcl,
  init,
  isLoggedIn,
  waitForTx,
  waitForMintTx,
  comics,
  dimensionX,
  dimensionXAdmin,
  
  // Ethereum
  isConnected,
  connect,
  signMessage,
  mint,
  mintPackNFTs,
  unstake,
  stake,
  turnInComics,
  turnInHeroes,

  // Helpers used in AsyncJsCall implementation
  get,
  strToPtr,
  toJsonPtr,
  openPopup,
  sleep,
}

async function waitForTx(txId) {
  await wagmiCore.waitForTransaction({hash:txId})
  return []
}

async function waitForMintTx(txId) {
  return await fcl.tx(txId).onceSealed()
}



// Ethereum
async function isConnected() {
  let account = await wagmiCore.getAccount()
  return account?.address ?? null
}

async function connect() {
  let network = await wagmiCore.getNetwork()
  console.log('network', network)
  if (network?.chain && network.chain.id != chainIds[config.CHAIN_ENV]) await wagmiCore.switchNetwork({chainId: chainIds[config.CHAIN_ENV]})
  let account = await wagmiCore.getAccount()
  if (account?.isConnected) return { address: account?.address }

  if (!account?.isConnected && !globalThis.crypthulhu.web3modal.isOpen) {
    globalThis.crypthulhu.web3modal.open()
    await sleep(2000)
    let isOpen = globalThis.crypthulhu.web3modal.isOpen
    while (isOpen) {
      await sleep(1000)
      isOpen = globalThis.crypthulhu.web3modal.isOpen
    }
  }

  account = await wagmiCore.getAccount()
  globalThis.crypthulhu.web3modal.close()
  return {
    address: account?.address
  }
}

// NOTE: Wagmi replaced ethers with viem... so instead of chaging everthing over
// to viem we do some extra steps to get the signer and things seem to work
// fine.  More info here -> https://wagmi.sh/react/ethers-adapters
const chainIds = {
  emulator: 1337,
  testnet: 5,
  mainnet: 1,
}
async function fetchSigner() {
  const walletClient = await wagmiCore.getWalletClient({chainId: chainIds[config.CHAIN_ENV]})
  console.log('walletClient', walletClient)
  const { account, chain, transport } = walletClient
  console.log('fetchSigner', {account, chain, transport})
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  const provider = new ethers.providers.Web3Provider(transport, network)
  const signer = provider.getSigner(account.address)
  return signer
}

async function signMessage(msg) {
  await connect()
  try {
    return await wagmiCore.signMessage(msg)
  } catch {
    return null
  }
}

async function mint(hash, signature, nonce) {
  const {address} = await connect()
  const signer = await fetchSigner()
  const dmxHero = new ethers.Contract(config.DMXHERO_ADDRESS, DMXHero.abi, signer)
  const tx = await dmxHero.mintCommonNFT(hash, signature, nonce, address)
  const receipt = await tx.wait()
  for (var ev of receipt?.events) {
    if (ev.address == dmxHero.address) {
      let parsedEv = dmxHero.interface.parseLog(ev)
      if (parsedEv.name == "Mint") return { tokenId: ev.args.tokenId }
    }
  }

  throw "No mint event";
}

async function mintPackNFTs(recipient, set, count) {
  const {address} = await connect()
  const signer = await fetchSigner()
  const dmxHero = new ethers.Contract(config.DMXHERO_ADDRESS, DMXHero.abi, signer)
  const dmxHeroStore = new ethers.Contract(config.DMXHEROSTORE_ADDRESS, DMXHeroStore.abi, signer)
  const price = await dmxHeroStore.nft_price()

  let gasLimit = await dmxHeroStore.estimateGas.mintPackNFTs(recipient, set, count, {value: price.mul(count)})
  const tx = await dmxHeroStore.mintPackNFTs(recipient, set, count, {value: price.mul(count), gasLimit})
  const receipt = await tx.wait()
  const tokenIds = []
  for (var ev of receipt?.events) {
    if (ev.address == dmxHero.address) {
      let parsedEv = dmxHero.interface.parseLog(ev)
      if (parsedEv.name == "Mint") tokenIds.push(parsedEv.args.tokenId)
    }
  }

  if (tokenIds.length > 0) return {tokenIds}

  throw "No mint event";
}

async function unstake(hash, signature, nftId, nonce) {
  const {address} = await connect()
  const signer = await fetchSigner()
  const dmxHeroStaker = new ethers.Contract(config.DMXHEROSTAKER_ADDRESS, DMXHeroStaker.abi, signer)
  const tx = await dmxHeroStaker.unstakeNFT(hash, signature, nftId, nonce, address)
  const receipt = await tx.wait()
  console.log('Transaction Receipt', receipt)
  for (var ev of receipt?.events) {
    if (ev.address == dmxHeroStaker.address) {
      let parsedEv = dmxHeroStaker.interface.parseLog(ev)
      if (parsedEv.name == "Mint") return { tokenId: ev.args.tokenId }
    }
  }
  return [receipt.transactionHash]
}

async function stake(nftId) {
  const {address} = await connect()
  const signer = await fetchSigner()
  const dmxHeroStaker = new ethers.Contract(config.DMXHEROSTAKER_ADDRESS, DMXHeroStaker.abi, signer)
  const tx = await dmxHeroStaker.stakeNFT(nftId)
  const receipt = await tx.wait()
  return [receipt.transactionHash]
}

async function turnInComics(comicIds, heroId) {
  console.log('turnInComics', {comicIds, heroId})
  const {address} = await connect()
  const signer = await fetchSigner()
  const dmxComics = new ethers.Contract(config.DMXCOMICS_ADDRESS, DMXComics.abi, signer)
  const tx = await dmxComics.turnIn(comicIds, heroId)
  const receipt = await tx.wait()
  console.log('turnInComics receipt', receipt)
  return [receipt.transactionHash]
}

async function turnInHeroes(heroIds, heroId) {
  console.log('turnInHeroes', {heroIds, heroId})
  const {address} = await connect()
  const signer = await fetchSigner()
  const dmxHero = new ethers.Contract(config.DMXHERO_ADDRESS, DMXHero.abi, signer)
  const tx = await dmxHero.turnIn(heroIds, heroId)
  const receipt = await tx.wait()
  console.log('turnInHero receipt', receipt)
  return [receipt.transactionHash]
}


// AsyncJsCall
function strToPtr(str) {
  // Notice Module is a global object defined by emscripten's runtime.
  // Unfortunately Unity doesn't export all the functions so add some of them
  // manually in AsyncJsCall.Init when starting the game
  if (str === undefined || str === null) return 0
  const strLen = Module.lengthBytesUTF8(str) + 1
  var strPtr = Module._malloc(strLen);
  Module.stringToUTF8(str, strPtr, strLen);
  return strPtr 
}

function toJsonPtr(obj) {
  return strToPtr(JSON.stringify(obj))
}

async function openPopup(url, target, width, height) {
  console.log('openPopup', url, target, width, height)
  const win = globalThis
  const w = width ?? 640
  const h = width ?? 770
  const y = win.top.outerHeight / 2 + win.top.screenY - h / 2
  const x = win.top.outerWidth / 2 + win.top.screenX - w / 2 
  win.open(
    url,
    target, 
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
  )
  return []
}