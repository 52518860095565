import { Web3Button } from '@web3modal/react'

import Link from 'next/link'
import config from 'global/config'

const NavbarLink = ({target, href, children}) => (
  <Link href={href}>
    <a
      target={target}
      className="inline-block uppercase hover:text-pink-500 hover:text-underline text-center align-middle p-2 h-auto md:p-4 mx-4 transform hover:scale-125 duration-300 ease-in-out"
    >
      {children}
    </a>
  </Link>
)

export default function Navbar() {
  return (
    <div className="relative w-full font-graveside fixed z-50">
      <div className="absolute inset-0 bg-black opacity-75 -z-10"></div>
      <div className="flex items-center p-2">
        <Link href="/">
          <a className="absolute">
            <img className="w-0 md:w-40" src={`${config.WEBCDN_URL}/images/crypthulhu-logo.png`} />
          </a>
        </Link>

        <div className="md:ml-40 w-full flex justify-end">
          <NavbarLink href="/game">Play</NavbarLink>
          {/* <NavbarLink href={config.WEBMINT_URL}>Mint</NavbarLink> */}
          <NavbarLink href="https://opensea.io/collection/dimensionx">Marketplace</NavbarLink>
          <div className='py-2 px-4'>
            <Web3Button label="CONNECT WALLET" />
          </div>
        </div>
      </div>
    </div>
  )
}
