import * as SocialIcons from './SocialIcons'

const SocialLink = props => (
  <a
    href={props.href}
    type="button"
    className="rounded-full border-2 border-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1"
  >
    {props.icon()}
  </a>
)

export default function Footer() {
  return (
    <footer className="text-center">
      <div className="container mx-auto px-6 pt-6 bg-black opacity-75">
        <div className="flex justify-center pb-6">
          <SocialLink
            href="https://discord.com/invite/dimensionx"
            icon={SocialIcons.Discord}
          />
          <SocialLink
            href="https://twitter.com/DimensionX_NFT"
            icon={SocialIcons.Twitter}
          />
          <SocialLink
            href="https://www.instagram.com/dimensionx_game/"
            icon={SocialIcons.Instagram}
          />
        </div>
      </div>

      <div className="text-center p-4 bg-black opacity-85">
        <span>©2021 </span>
        <a href="https://crypthulhu.io">Crypthulhu Studios Inc.</a>
        <br/>
        <a href="/tos">Terms Of Service</a>
        <span>&nbsp;-&nbsp;</span>
        <a href="/privacy">Privacy Policy</a>
        <span>&nbsp;-&nbsp;</span>
        <a href="/license">End User License Agreement</a>
      </div>
    </footer>
  )
}
