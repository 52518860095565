import {createContext, useContext, useState, useEffect} from 'react'

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { useWeb3Modal, Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { mainnet, goerli, localhost } from '@wagmi/core/chains'
import { alchemyProvider } from '@wagmi/core/providers/alchemy'

import Lottie from 'lottie-react'
import dotsAnim from 'public/anims/dots.json'

import 'common/unityInterop'
import config from 'global/config'



// ~~~ WalletConnect/Wagmi/Ethers Setup ~~~
const projectId = config.W3M_PROJECT_ID // Walletconnect projectId from https://cloud.walletconnect.com

const chainEnvNetworks = {
  emulator: localhost,
  testnet: goerli,
  mainnet: mainnet,
}

const chains = [chainEnvNetworks[config.CHAIN_ENV]]

const chainEnvProviders = {
  emulator: () => w3mProvider({ projectId, chains }),
  testnet: () => alchemyProvider({apiKey: config.ALCHEMY_API_KEY}),
  mainnet: () => alchemyProvider({apiKey: config.ALCHEMY_API_KEY}),
}

const { publicClient } = configureChains(chains, [chainEnvProviders[config.CHAIN_ENV]()])
// const chains = [localhost]
// const { publicClient } = configureChains(chains, [w3mProvider({ projectId , chains })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({projectId, version: 2, chains}),
  publicClient,
})
// console.log('wagmiConfig', wagmiConfig)

export const ethereumClient = new EthereumClient(wagmiConfig, chains)
// console.log('ethereumClient', ethereumClient)
// ^^^ WalletConnect/Wagmi/Ethers Setup ^^^



const AppContext = createContext()

export function AppContextProvider({children}) {
  const [isLoading, setIsLoading] = useState(false)
  const web3modal = useWeb3Modal()

  useEffect(() => {
    // XXX: We put this here so we can reach it from unityInterop
    globalThis.crypthulhu.web3modal = web3modal
  }, [web3modal])

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <AppContext.Provider
          value={{
            isLoading,
            setIsLoading,
          }}
        >
          <div className="w-full h-full flex">
            {children}
            {isLoading && (
              <div className="fixed z-50 h-full inset-0 bg-black opacity-75 blur blur-md flex justify-center">
                <Lottie
                  style={{width: '5rem'}}
                  loop
                  autoPlay
                  animationData={dotsAnim}
                />
              </div>
            )}
          </div>
        </AppContext.Provider>
      </WagmiConfig>
      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        defaultChain={chainEnvNetworks[config.CHAIN_ENV]}
        // Limit wallet selection to Metamask
        explorerRecommendedWalletIds={['c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96']}
        explorerExcludedWalletIds={'ALL'}
      />
    </>
  )
}

export function useAppContext() {
  return useContext(AppContext)
}