import * as fcl from '@onflow/fcl'
import * as t from '@onflow/types'

import * as flow from './flow.mjs'
import { getKeyIndex, getUniqueKeyIndex } from './flowKeys.mjs'

import mintCustomCdc from './cadence/transactions/dimensionx/mint_custom_nft.cdc'
import mintGenesisCdc from './cadence/transactions/dimensionx/mint_genesis_nft.cdc'
import mintCdc from './cadence/transactions/dimensionx/mint_nft.cdc'
import mintStakedCdc from './cadence/transactions/dimensionx/mint_staked_nft.cdc'
import transferNftCdc from './cadence/transactions/dimensionx/transfer_nft.cdc'
import unstakeNftCdc from './cadence/transactions/dimensionx/unstake_nft.cdc'
import unstakeManyNftCdc from './cadence/transactions/dimensionx/unstake_many_nft.cdc'
import setCrypthulhuSleepTimeCdc from './cadence/transactions/dimensionx/set_crypthulhu_sleep_time.cdc'
import crypthulhuAwakeCdc from './cadence/transactions/dimensionx/crypthulhu_awake.cdc'

export async function mint({recipient, amount, type}) {
  const authorization = flow.authorizeAdmin(getUniqueKeyIndex());
  const getMintTransaction = (type) => {
    switch (type) {
      case 'custom': return mintCustomCdc
      case 'genesis': return mintGenesisCdc
    }
    return mintCdc
  }
  const txId = await flow.sendTx({
    cadence: getMintTransaction(type),
    args: [
      fcl.arg(recipient, t.Address),
      fcl.arg(amount, t.UInt64),
    ],
    payer: authorization,
    proposer: authorization,
    authorizations: [authorization],
    skipSeal: true
  })

  const result = await fcl.tx(txId).onceSealed()
  return [txId, result]
}

export async function mintStaked({recipient, amount, type}, sql) {
  const actualKeyIndex = await getKeyIndex(sql);  
  const authorization = flow.authorizeAdmin(actualKeyIndex);
  const authorizationProposer = flow.authorizeAdminProposer(actualKeyIndex);
  const txId = await flow.sendTx({
    cadence: mintStakedCdc,
    args: [
      fcl.arg(recipient, t.Address),
      fcl.arg(amount, t.UInt64),
    ],
    payer: authorization,
    proposer: authorizationProposer,
    authorizations: [authorization],
    skipSeal: true
  })

  return [txId]
}

export async function crypthulhuAwake() {
  const authorization = flow.authorizeAdmin(getUniqueKeyIndex());
  const txId = await flow.sendTx({
    cadence: crypthulhuAwakeCdc,
    payer: authorization,
    proposer: authorization,
    authorizations: [authorization],
    skipSeal: true
  })

  const result = await fcl.tx(txId).onceSealed()
  return [txId, result]
}

export async function setCrypthulhuSleepTime(sleepTime) {
  const authorization = flow.authorizeAdmin(getUniqueKeyIndex());
  const txId = await flow.sendTx({
    cadence: setCrypthulhuSleepTimeCdc,
    args: [
      fcl.arg(sleepTime, t.UFix64),
    ],
    payer: authorization,
    proposer: authorization,
    authorizations: [authorization],
    skipSeal: true
  })

  const result = await fcl.tx(txId).onceSealed()
  return [txId, result]
}

export async function transfer({id, recipient}) {
  const authorization = flow.authorizeAdmin(getUniqueKeyIndex());
  const txId = await flow.sendTx({
    cadence: transferNftCdc,
    args: [
      fcl.arg(recipient, t.Address),
      fcl.arg(id, t.UInt64),
    ],
    payer: authorization,
    proposer: authorization,
    authorizations: [authorization],
    skipSeal: true
  })

  const result = await fcl.tx(txId).onceSealed()
  return [txId, result]
}

export async function unstakeNft(id, sql) {
  const actualKeyIndex = await getKeyIndex(sql);  
  const authorization = flow.authorizeAdmin(actualKeyIndex);
  const authorizationProposer = flow.authorizeAdminProposer(actualKeyIndex);
  const txId = await flow.sendTx({
    cadence: unstakeNftCdc,
    args: [fcl.arg(id, t.UInt64)],
    payer: authorization,
    proposer: authorizationProposer,
    authorizations: [authorization],
    skipSeal: true,
  })
  return [txId]
}

export async function unstakeManyNft(address) {
  const authorization = flow.authorizeAdmin() // TODO: fix this once we decide if we're going to use this function or not, currently it won't work
  const txId = await flow.sendTx({
    cadence: unstakeManyNftCdc,
    args: [fcl.arg(address, t.Address)],
    payer: authorization,
    proposer: authorization,
    authorizations: [authorization],
    skipSeal: true,
  })

  const result = await fcl.tx(txId).onceSealed()
  return [txId, result]
}