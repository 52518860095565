import * as fcl from '@onflow/fcl'
import * as flow from './flow.mjs'

export * as dimensionX from './dimensionX.js'
export * as dimensionXAdmin from './dimensionXAdmin.js'
export * as dimensionXStore from './dimensionXStore.js'
export * as dimensionXStoreAdmin from './dimensionXStoreAdmin.js'

export * as comics from './comics.js'

export async function init(env) {
  const fclConfig = {
    'accessNode.api': env.ACCESS_API,
    'discovery.wallet': env.WALLET_DISCOVERY,
    'discovery.wallet.method': env.WALLET_DISCOVERY_METHOD,

    'app.detail.title': env.APP_DETAIL_TITLE,
    'app.detail.icon': env.APP_DETAIL_ICON,

    '0xDimensionX': env.DIMENSIONX_ADDRESS,
    '0xDimensionXStore': env.DIMENSIONXSTORE_ADDRESS,
    '0xDimensionXComics': env.DIMENSIONXCOMICS_ADDRESS,
    '0xFungibleToken': env.FUNGIBLETOKEN_ADDRESS,
    '0xFlowToken': env.FLOWTOKEN_ADDRESS,
    '0xNonFungibleToken': env.NONFUNGIBLETOKEN_ADDRESS,
    '0xMetadataViews': env.METADATAVIEWS_ADDRESS
  }

  console.log('CHAIN_ENV', env.CHAIN_ENV)
  console.log('fcl.config', fclConfig)

  flow.init(env)
  fcl.config(fclConfig)
  fcl.currentUser().subscribe((user) => fclUser = user)
}

let fclUser

export async function isLoggedIn() {
  var res = fclUser?.addr
  return res
}
