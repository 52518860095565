const CHAIN_ENV = process.env.NEXT_PUBLIC_CHAIN_ENV

const ALCHEMY_API_KEY = process.env.NEXT_PUBLIC_ALCHEMY_API_KEY
const W3M_PROJECT_ID = process.env.NEXT_PUBLIC_W3M_PROJECT_ID

const DMXHERO_ADDRESS = process.env.NEXT_PUBLIC_DMXHERO_ADDRESS
const DMXHEROSTAKER_ADDRESS = process.env.NEXT_PUBLIC_DMXHEROSTAKER_ADDRESS
const DMXHEROSTORE_ADDRESS = process.env.NEXT_PUBLIC_DMXHEROSTORE_ADDRESS
const DMXCOMICS_ADDRESS = process.env.NEXT_PUBLIC_DMXCOMICS_ADDRESS

const UNITY_BUILD_URL = process.env.NEXT_PUBLIC_UNITY_BUILD_URL
const UNITY_BUILD_SUFFIX = process.env.NEXT_PUBLIC_UNITY_BUILD_SUFFIX
const UNITY_BUILD_TARGET = process.env.NEXT_PUBLIC_UNITY_BUILD_TARGET
const UNITY_BUILD_NUMBER = process.env.NEXT_PUBLIC_UNITY_BUILD_NUMBER

const WEBMINT_URL = process.env.NEXT_PUBLIC_WEBMINT_URL
const METADATA_URL = process.env.NEXT_PUBLIC_METADATA_URL
const CDN_URL = process.env.NEXT_PUBLIC_CDN_URL
const WEBCDN_URL = process.env.NEXT_PUBLIC_WEBCDN_URL

const config = {
  CHAIN_ENV,

  ALCHEMY_API_KEY,
  W3M_PROJECT_ID,

  DMXHERO_ADDRESS,
  DMXHEROSTAKER_ADDRESS,
  DMXHEROSTORE_ADDRESS,
  DMXCOMICS_ADDRESS,

  UNITY_BUILD_URL,
  UNITY_BUILD_SUFFIX,
  UNITY_BUILD_TARGET,
  UNITY_BUILD_NUMBER,

  WEBMINT_URL,
  METADATA_URL,
  CDN_URL,
  WEBCDN_URL,
}

export default config
